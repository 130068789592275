import React, { useState } from "react"
import axios from "axios"
import {
  Grid,
  Button,
  Typography,
  Container,
  TextField,
  CircularProgress,
  makeStyles,
  Paper,
  withStyles,
} from "@material-ui/core"
import Hero from "../components/hero"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  submissionText: {
    fontSize: "1.2rem",
    fontFamily: "Nunito",
    padding: "50px",
    textAlign: "center",
  },
  bottom: {
    paddingBottom: "20px",
  },
}))
const StyledButton = withStyles(theme => ({
  root: {
    borderRadius: "50px",
    backgroundColor: "#0072ab",
    color: "white",
    textTransform: "none",
    margin: "20px",
    fontSize: "1.2rem",
    padding: "7px 20px",
    "&:hover": {
      backgroundColor: "#177bad",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      margin: "10px",
    },
  },
}))(Button)
export default function ThankYou() {
  const classes = useStyles()

  return (
    <>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={10}>
          <Container className={classes.container}>
            <Hero
              title="Thank you for your purchase!"
              bodyText="Your order will be with you soon. We sincerely appreciate your support!"
            />
            <AniLink
              style={{ textAlign: "center", textDecoration: "none" }}
              paintDrip
              hex="#ffffff"
              to="/"
            >
              <StyledButton variant="contained">
                Back to Front Page
              </StyledButton>
            </AniLink>
          </Container>
        </Grid>
      </Grid>
    </>
  )
}
